@import "minima";

$header-colour: #700;

.site-header {
  border-top-color: $header-colour;
}

.site-title, .site-title:visited {
  color: $header-colour;
}

#main_photo {
  max-width: 10rem;
  float: right;
  margin: 0 0 .5rem .5rem;
}

form {
  label { font-weight: bold; }
  label, input, button, textarea { display: block; }
  input[name=_gotcha] { display: none; }
  textarea {
    min-width: 60%;
    min-height: 5rem;
  }
}

.project_desc {
  font-style: inherit;
  color: inherit;
  letter-spacing: inherit;
}

.footnotes {
  font-size: 0.8em;
  padding-top: 1em;
  border-top: 1px solid #eee;
  li p { margin: 0 0 .5rem }
}
